import React, { useEffect, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeliveryWorkTable,
  DesktopDatepicker,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Autocomplete, Button, Card, Switch, TextField } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function DeliveryWorks
 * @description Display a collections or a list of DeliveryWorks from database
 */

export default function MyDeliveryWorks({ title, subtitle }) {
  const dispatch = useDispatch();
  const step = useSelector((state) => state.step);
  const shippingRound = useSelector((state) => state.shippingRound);
  const shippingType = useSelector((state) => state.shippingType);
  const me = useSelector((state) => state.me);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [orderByField, setOrderByField] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');
  const [useRedis, setIsUseRedis] = useState(true);
  const [total, setTotal] = useState(undefined);

  const [includeLog, setIsIncludeLog] = useState(false);
  const [selectedShippingRound, setSelectedShippingRound] = useState();
  const [selectedShippingType, setSelectedShippingType] = useState();
  const [selectedShippingDate, setSelectedShippingDate] = useState(null);

  useEffect(() => {
    dispatch(actions.stepReset());
    return () => {};
  }, [page]);

  const getAllStep = () => {
    try {
      dispatch(
        actions.stepAll({
          page,
          size,
          isDeliveryStep: true,
          fetchProcess: true,
          name,
          forceWithoutCache: useRedis ? '' : true,
          orderBy,
          orderByField,
          includeLog: includeLog ? true : '',
          shippingDate: selectedShippingDate
            ? dayjs(selectedShippingDate).format('YYYY-MM-DD')
            : '',
          shippingRound: selectedShippingRound?._id || '',
          shippingType: selectedShippingType?._id || '',
          employee: me?._id || '',
        }),
      );
    } catch (error) {
      dispatch(actions.stepError());
    }
  };

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.shippingRoundAll({
          name: '',
          page: 1,
          size: config.maxFetchSize,
        }),
      );
      dispatch(
        actions.shippingTypeAll({
          name: '',
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      alert(`ไม่สามารถดึงข้อมูลสนับสนุนได้ ${error.message}`);
    }
  };

  useEffect(() => {
    getAllStep();
    return () => {};
  }, [
    page,
    size,
    name,
    orderBy,
    orderByField,
    useRedis,
    selectedShippingRound,
    selectedShippingType,
    selectedShippingDate,
    includeLog,
  ]);

  useEffect(() => {
    queryDataFromServer();

    return () => {};
  }, []);

  useEffect(() => {
    setTotal(step?.total);
    return () => {};
  }, [step]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  // Component Rendering
  const renderTitle = () => (
    <div className="flex justify-between items-center">
      <ViewTitle title={title} subtitle={subtitle} />
      <div>
        <Button
          variant="contained"
          color="teal"
          startIcon={<RefreshCw size={16} />}
          onClick={() => {
            setIsUseRedis(false);
          }}
        >
          รีเฟรช
        </Button>
      </div>
    </div>
  );

  if (step.isLoading) {
    return <Loading />;
  }
  if (!step.isLoading && step.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <Card>
            <div className="p-2">
              <div className="flex flex-wrap items-center">
                <div className="w-full md:w-1/2 lg:w-1/3 py-2 px-1">
                  <TextField
                    fullWidth
                    size="small"
                    label="ค้นหา"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-1/6 py-2 px-1">
                  {!_.isEmpty(shippingRound?.rows) && (
                    <Autocomplete
                      disablePortal
                      options={shippingRound?.rows}
                      size="small"
                      placeholder="รอบจัดส่ง"
                      onChange={(e, newValue) => {
                        setSelectedShippingRound(newValue);
                      }}
                      className="z-40"
                      // prettier-ignore
                      getOptionLabel={(option) => `${option?.name || ''}`
                }
                      renderInput={(params) => (
                        <TextField label="รอบจัดส่ง" {...params} />
                      )}
                    />
                  )}
                </div>
                <div className="w-full md:w-1/2 lg:w-1/6 py-2 px-1">
                  {!_.isEmpty(shippingType?.rows) && (
                    <Autocomplete
                      disablePortal
                      options={shippingType?.rows}
                      size="small"
                      placeholder="วิธีการจัดส่ง"
                      onChange={(e, newValue) => {
                        setSelectedShippingType(newValue);
                      }}
                      className="z-40"
                      // prettier-ignore
                      getOptionLabel={(option) => `${option?.name || ''}`
                }
                      renderInput={(params) => (
                        <TextField label="วิธีการจัดส่ง" {...params} />
                      )}
                    />
                  )}
                </div>
                <div className="w-full md:w-1/2 lg:w-1/6 py-2 px-1">
                  <DesktopDatepicker
                    fullWidth
                    size="small"
                    label="วันที่จัดส่ง"
                    value={selectedShippingDate}
                    onChange={(e) => setSelectedShippingDate(e)}
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-1/6 py-2 px-1">
                  <div className="flex gap-1 items-center">
                    <Switch
                      checked={includeLog}
                      onChange={(e) => setIsIncludeLog(e.target.checked)}
                    />
                    <div>รวมคำสั่งที่เสร็จแล้ว</div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="my-2">
          <DeliveryWorkTable
            handleChangePage={(e, newPage) => {
              setPage(newPage + 1);
            }}
            handleChangeRowsPerPage={(e) => {
              setSize(e.target.value);
              setPage(1);
            }}
            orderBy={orderBy}
            orderByField={orderByField}
            setOrderBy={setOrderBy}
            setOrderByField={setOrderByField}
            page={page}
            showActionButton
            size={size}
            step={step}
            total={total}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

MyDeliveryWorks.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MyDeliveryWorks.defaultProps = {
  title: '',
  subtitle: '',
};
