/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import { Button, Card, CardContent } from '@mui/material';
import { getToken } from 'firebase/messaging';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { messaging, vapidKey } from '@/configs/firebase';

import * as actions from '../../redux/actions';

function PersonalProfile({ title, subtitle }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);

  useEffect(() => {
    dispatch(actions.meGet());
    return () => {};
  }, []);

  const handleRemoveNotify = async (tokenNumber) => {
    const payload = {};
    payload[`web_push_token_${tokenNumber}`] = null;
    payload[`web_push_device_name_${tokenNumber}`] = null;

    await dispatch(actions.employeePut(me?.userData?._id, payload));
    await dispatch(actions.meGet());
  };

  const requestNotification = async (tokenNumber) => {
    let browser;
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      if (navigator.userAgent.indexOf('Edg') !== -1) {
        browser = 'Microsoft Edge';
      } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
        browser = 'Google Chrome';
      } else if (navigator.userAgent.indexOf('Safari') !== -1) {
        browser = 'Safari';
      } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        browser = 'Mozilla Firefox';
      } else {
        browser = 'Internet Browser';
      }

      let platform;
      if (navigator.platform === 'Win32') platform = 'Windows';
      else if (navigator.platform.indexOf('Linux arm') !== -1) {
        platform = 'Android';
      } else platform = navigator.platform;
      // navigator.platform !== 'iPhone' &&
      // navigator.platform !== 'iPad' &&
      // navigator.platform !== 'iPod' &&
      if (messaging) {
        getToken(messaging, { vapidKey }).then((token) => {
          const payload = {};
          console.log('Get Token', token);
          payload[`web_push_token_${tokenNumber}`] = token;
          payload[
            `web_push_device_name_${tokenNumber}`
          ] = `${browser} ${platform}`;
          dispatch(actions.employeePut(me?.userData?._id, payload))
            .then(() => {
              console.log('Payload', payload);
              Swal.fire({
                title: 'เปิดการแจ้งเตือนสำเร็จ',
                icon: 'success',
              });
              dispatch(actions.meGet());
            })
            .catch((err) => {
              Swal.fire({
                title: 'ไม่สามารถเปิดการแจ้งเตือนได้',
                text: err?.message,
                icon: 'error',
              });
            });
        });
      } else {
        Swal.fire({
          title: 'ระบบส่งข้อความไม่สามารถทำงานได้',
          icon: 'error',
        });
      }
    } else {
      Swal.fire({
        title: 'กรุณาอนุญาตการแจ้งเตือนก่อน',
        icon: 'error',
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderEditButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/profile/edit">
        <Button variant="contained" color="warning">
          แก้ไข
        </Button>
      </Link>
    </div>
  );

  const renderNofifyButton = (tokenNumber) => {
    if (me?.userData?.[`web_push_token_${tokenNumber}`]) {
      return (
        <div className="flex justify-center gap-2">
          <div>{me?.userData?.[`web_push_device_name_${tokenNumber}`]}</div>
          <Button
            variant="contained"
            color="warning"
            onClick={() => requestNotification(tokenNumber)}
          >
            เปลี่ยนบัญชี
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={() => handleRemoveNotify(tokenNumber)}
          >
            ยกเลิก
          </Button>
        </div>
      );
    }
    return (
      <a>
        <Button
          variant="contained"
          color="primary"
          onClick={() => requestNotification(tokenNumber)}
        >
          รับการแจ้งเตือนผ่าน
        </Button>
      </a>
    );
  };

  const renderDetail = () => {
    const {
      firstname = ' ',
      lastname = ' ',
      phone_number = '',
      department: { name: department_name = '' },
      image = '',
    } = me.userData;
    return (
      <div className="w-full">
        <Card>
          <div className="flex flex-wrap p-4">
            <div className="w-full">
              <div className="flex justify-center">
                <img src={image.url} className="w-60 rounded-lg" />
              </div>
            </div>

            <div className="w-full text-center">
              <div className="text-2xl font-semibold font-display text-blue-800 py-2 mt-2">
                {firstname} {lastname}
              </div>
              <div className="py-2">เบอร์โทรศัพท์ : {phone_number}</div>
              <div className="py-2">แผนก : {department_name}</div>
              {!_.isEmpty(me?.userData?.other_departments) &&
                _.map(me?.userData?.other_departments, (_otherDept, index) => (
                  <div key={index}>{_otherDept?.name} </div>
                ))}
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const renderSetting = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="font-semibold font-display  ">การตั้งค่า</div>
          <div className="flex flex-wrap w-full my-2">
            <div className="w-1/2 py-2 px-2 self-center">
              การแจ้งเตือน ช่องทางที่ 1
            </div>
            <div className="w-1/2 py-2 px-2  flex justify-end">
              {renderNofifyButton(1)}
            </div>{' '}
            <div className="w-1/2 py-2 px-2 self-center">
              การแจ้งเตือน ช่องทางที่ 2
            </div>
            <div className="w-1/2 py-2 px-2  flex justify-end">
              {renderNofifyButton(2)}
            </div>
            <hr className="w-full" />
            <div className="w-1/2 py-4 px-2 self-center">รหัสผ่าน</div>
            <div className="w-1/2 py-4 px-2  flex justify-end">
              <Link to="/profile/edit-password">
                <Button variant="contained" color="inherit">
                  เปลี่ยนรหัสผ่าน
                </Button>
              </Link>
            </div>
            <hr className="w-full" />
            <div className="w-1/2 py-4 px-2 self-center">
              ปรับแต่งหน้าตาการใช้งาน (Personalize)
            </div>
            <div className="w-1/2 py-4 px-2  flex justify-end">
              <Link to="/profile/personalize">
                <Button variant="contained" color="inherit">
                  ตกแต่ง
                </Button>
              </Link>
            </div>
            <hr className="w-full" />{' '}
            <div className="w-1/2 py-4 px-2 self-center">ลายเซนต์</div>
            <div className="w-1/2 py-4 px-2  flex justify-end">
              <Link to={`/profile/signature/${me?.userData?._id}`}>
                <Button variant="contained" color="inherit">
                  ตั้งค่าลายเซนต์
                </Button>
              </Link>
            </div>
            <hr className="w-full" />
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderProblemAndComplacency = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="font-semibold font-display">แจ้งปัญหา/ให้คำแนะนำ</div>
          <div className="flex flex-wrap w-full my-2">
            <div className="w-1/2 py-2 px-2 self-center">
              แจ้งปัญหาการใช้งาน
            </div>
            <div className="w-1/2 py-2 px-2 flex justify-end ">
              <Link to="/profile/report-problem">
                <Button variant="contained" color="inherit">
                  แจ้งปัญหา
                </Button>
              </Link>
            </div>
            <hr className="w-full" />
            {/* <div className="w-1/2 py-4 px-2 self-center">รหัสผ่าน</div>
            <div className="w-1/2 py-4 px-2  flex justify-end">
              <Link to="/profile/edit-password">
                <Button variant="contained" color="inherit">
                  เปลี่ยนรหัสผ่าน
                </Button>
              </Link>
            </div> */}
            <hr className="w-full" />
          </div>
        </CardContent>
      </Card>
    </div>
  );

  if (me.isLoading) {
    return <Loading />;
  }
  if (!me.isLoading && me.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="mt-6">{renderEditButton()}</div>
        </div>
        {renderDetail()}
        {renderSetting()}
        {renderProblemAndComplacency()}
      </div>
    );
  }
  return <Error />;
}
PersonalProfile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PersonalProfile.defaultProps = {
  title: '',
  subtitle: '',
};

export default PersonalProfile;
