import api from '../../../configs/api';
import {
  STEP_ALL,
  STEP_DELETE,
  STEP_ERROR,
  STEP_GET,
  STEP_LOADING,
  STEP_POST,
  STEP_PUT,
} from '../types';

export const stepAll = ({
  name = '',
  size = '',
  page = 1,
  status = '',
  department = '',
  completed = '',
  all = false,
  startDate = '',
  endDate = '',
  deleted = '',
  incompleted = true,
  assigned = '',
  employee = '',
  forceWithoutCache = '',
  fetchProcess = '',
  fetchEmployeeData = '',
  fetchManufacturingOrder = '',
  fetchMaterial = '',
  fetchProduct = '',
  fetchMeasurement = '',
  orderBy = '',
  orderByField = '',
  includeLog = '',
  productCode = '',
  assigner = '',
  assignerDepartment = '',
  // For Delivery
  isDeliveryStep = '',
  shippingRound = '',
  shippingType = '',
  shippingDate = '',
  onlyDeliveryAvailable = '',
}) => async (dispatch) => {
  try {
    const searchParam = new URLSearchParams({
      productName: name,
      size,
      page,
      status,
      department,
      completed,
      all,
      startDate,
      endDate,
      deleted,
      incompleted,
      assigned,
      employee,
      forceWithoutCache,
      fetchProcess,
      fetchEmployeeData,
      fetchManufacturingOrder,
      fetchMaterial,
      fetchProduct,
      fetchMeasurement,
      orderBy,
      orderByField,
      includeLog,
      productCode,
      assigner,
      assignerDepartment,
      isDeliveryStep,
      shippingDate,
      shippingRound,
      shippingType,
      onlyDeliveryAvailable,
    });
    const { data, status: apiStatus } = await api.get(
      `${process.env.REACT_APP_API_URL}/step?${searchParam.toString()}`,
    );
    if (apiStatus === 200) {
      dispatch({ type: STEP_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: STEP_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const stepGet = (id, { fetchMeasurement = false } = {}) => async (
  dispatch,
) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/step/${id}?all=true&fetchMeasurement=${fetchMeasurement}`,
    );
    if (status === 200) {
      dispatch({ type: STEP_GET, payload: data });
    }
    if (status === 404) {
      dispatch({ type: STEP_ERROR, payload: { message: '' } });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: STEP_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const stepPost = (payload) => async (dispatch) => {
  try {
    dispatch({ type: STEP_LOADING });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/step`, {
      ...payload,
    });

    dispatch({ type: STEP_POST, payload: data });
  } catch (error) {
    console.error(error?.response?.data?.error?.message);
    dispatch({ type: STEP_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const stepStateSet = (data) => async (dispatch) => {
  dispatch({ type: STEP_ALL, payload: data });
};

export const stepStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: STEP_GET, payload: data });
};

export const stepPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: STEP_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/step/${id}`,
      payload,
    );
    dispatch({ type: STEP_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: STEP_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const stepDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: STEP_DELETE });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/step/${id}`,
    );
    dispatch({ type: STEP_DELETE, payload: data });
  } catch (error) {
    const { status } = error.request;
    console.error(error);

    if (status === 403) {
      // prettier-ignore
      const message = 'ไม่สามารถลบข้อมูลได้';
      dispatch({ type: STEP_ERROR, payload: { message } });
      throw new Error(message);
    } else {
      const message = 'เกิดข้อผิดพลาด';
      dispatch({ type: STEP_ERROR, payload: { message } });
      throw new Error(message);
    }
  }
};

export const stepAudit = ({ referenceNumber }) => async (dispatch) => {
  try {
    dispatch({ type: STEP_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/step/audit-log`,
      {
        referenceNumber,
      },
    );
    dispatch({ type: STEP_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: STEP_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const stepForceMoveAudit = ({ referenceNumber, selectedTime }) => async (
  dispatch,
) => {
  try {
    dispatch({ type: STEP_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/step/audit-force-move`,
      {
        referenceNumber,
        selectedTime,
      },
    );
    dispatch({ type: STEP_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: STEP_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const stepAuditOne = (id) => async (dispatch) => {
  try {
    dispatch({ type: STEP_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/step/audit-one/${id}`,
    );
    dispatch({ type: STEP_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: STEP_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const stepReset = (id) => async (dispatch) => {
  dispatch({ type: STEP_LOADING });
};

export const stepError = () => async (dispatch) => {
  dispatch({ type: STEP_ERROR });
};

export const stepLoading = () => async (dispatch) => {
  dispatch({ type: STEP_LOADING });
};
