/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { ArrowDown, ArrowUp, UserPlus as UserPlusIcon } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MANUFAC_ORDER_STATUS } from '@iarcpsu/emanufac-constant';
import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import theme from '@/theme';
import { currencyFormatter } from '@/utils/functions';
import { MMS } from '@/utils/Proptypes';

import 'dayjs/locale/th';

import NameBox from '../../Box/Common/NameBox';

dayjs.locale('th');
dayjs.extend(buddhistEra);

/**
 * @function DeliveryTable
 * @description ตารางรายการงาน เฉพาะงานจัดส่ง ( Delivery Works Table) หรือ Steps ใช้ในหน้าของรายการงาน (Works)
 * @memberof MMS
 * @returns {React.Component} React Component
 */
export default function DeliveryWorkTable({
  step,
  page,
  size,
  total,
  handleChangeRowsPerPage,
  handleAcceptWork,
  handleChangePage,
  handleAuditStep = () => {},
  orderByField,
  orderBy,
  setOrderByField,
  setOrderBy,
  showActionButton = true,
}) {
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const [selectStepId, setSelectStepId] = useState(null);
  const [count, setCount] = useState(0);
  const showWorkResponsible = (row) => {
    if (row?.responsible?.employee && !_.isEmpty(row?.responsible?.employee)) {
      return <NameBox user={row?.responsible?.employee} />;
    }
    return <div> ยังไม่มีผู้รับผิดชอบในงานนี้</div>;
  };

  const statusColorClass = (status) => {
    switch (status) {
      case MANUFAC_ORDER_STATUS.IN_PROGRESS.status_code:
        return 'bg-blue-300';
      case MANUFAC_ORDER_STATUS.LATED.status_code:
        return 'bg-yellow-300';
      case MANUFAC_ORDER_STATUS.SUCCESS.status_code:
        return 'bg-green-300';
      case MANUFAC_ORDER_STATUS.CANCEL.status_code:
        return 'bg-red-300';
      default:
        return '';
    }
  };

  const statusDeliveryBadge = (delivery) => {
    if (delivery?.delivered) {
      return (
        <div className="bg-green-200 rounded-md text-center p-1">ส่งแล้ว</div>
      );
    }
    return (
      <div className="bg-red-200 rounded-md text-center p-1">ยังไม่ส่ง</div>
    );
  };

  const handleSpecialFunctionForAudit = (id) => {
    if (id === selectStepId) {
      setCount(count + 1);
    } else {
      setSelectStepId(id);
      setCount(1);
    }
  };

  useEffect(() => {
    if (count === 5) {
      handleAuditStep(selectStepId);
    }

    return () => {};
  }, [count]);

  return (
    <div className="w-full my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="10%" />
              <col width="10%" />
              <col width="25%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold ">วันที่ต้องจัดส่ง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่องาน</div>
                </TableCell>{' '}
                <TableCell
                // className="cursor-pointer hover:bg-gray-100"
                // onClick={() => {
                //   setOrderByField('status');
                //   if (orderBy === 'asc') {
                //     setOrderBy('desc');
                //   } else {
                //     setOrderBy('asc');
                //   }
                // }}
                >
                  <div className="font-bold flex">
                    สถานะจัดส่ง
                    {/* {orderByField === 'status' && orderBy === 'desc' ? (
                      <ArrowDown size="16" className="self-center" />
                    ) : (
                      <ArrowUp size="16" className="self-center" />
                    )} */}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รอบการจัดส่ง </div>
                </TableCell>
                <TableCell className="cursor-pointer hover:bg-gray-100">
                  <div className="font-bold">วิธีการจัดส่ง </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผู้รับผิดชอบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(step?.rows) ? (
                step?.rows?.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <p>
                        {row?.process?.delivery?.delivery_date
                          ? dayjs(row?.process?.delivery?.delivery_date).format(
                              'D MMM BBBB ',
                            )
                          : '-'}
                      </p>
                      {row?.log && (
                        <Chip label="ลบ หรือ สำเร็จแล้ว" size="small" />
                      )}
                    </TableCell>

                    <TableCell>
                      <div
                        className="mt-2 font-semibold"
                        onClick={() => {
                          handleSpecialFunctionForAudit(row?._id);
                        }}
                      >
                        {row?.customer?.type_code || ''}{' '}
                        {row?.customer?.name || '-'}
                      </div>
                      <Link
                        className="text-theme-500  font-semibold font-display cursor-pointer hover:text-indigo-900"
                        to={`/mms/works/view/${row?._id}`}
                        style={{
                          color: theme?.palette?.primary?.dark,
                        }}
                      >
                        <div className="">
                          <span className="font-bold mr-2">
                            {row?.product?.type_code}
                          </span>{' '}
                          <span>{row?.product?.name}</span>{' '}
                        </div>
                      </Link>
                      <Link
                        className="text-gray-500 cursor-pointer hover:text-gray-900"
                        to={
                          information?.setting?.mms?.dashboard?.showDashboardC
                            ? `/mms/manufacturing-orders-c/view/${row?.manufacturing_order}`
                            : `/mms/manufacturing-orders/view/${row?.manufacturing_order}`
                        }
                      >
                        <div className="text-base hover:text-indigo-900 ">
                          MO
                          {row?.mo_number}{' '}
                          {information?.setting?.mms?.showBatchNumber && (
                            <Chip
                              size="small"
                              label={`Batch No.${row?.batch_no || ''}`}
                            ></Chip>
                          )}
                        </div>
                      </Link>{' '}
                      <div className="flex">
                        {row?.current && (
                          <div className="p-1 px-2 my-2 text-green-600 bg-green-100 rounded-md ">
                            พร้อมจัดส่ง
                          </div>
                        )}{' '}
                        {!row?.current &&
                          (row?.status ===
                            MANUFAC_ORDER_STATUS.IN_PROGRESS.status_code ||
                            row?.status ===
                              MANUFAC_ORDER_STATUS.LATED.status_code) && (
                            <div className="p-1 px-2 my-2 text-yellow-600 bg-yellow-100 rounded-md ">
                              งานยังไม่พร้อมจัดส่ง
                            </div>
                          )}{' '}
                      </div>
                      <div>
                        {(row?.deleted || row?.process?.deleted) && (
                          <div className="text-red-500 font-semibold">
                            ยกเลิกแล้ว
                          </div>
                        )}
                      </div>
                      {typeof row?.process !== 'string' ? (
                        <div>
                          {row?.process?.quantity >= 1 && (
                            <div>
                              จำนวน{' '}
                              {currencyFormatter.format(row?.process?.quantity)}{' '}
                              {row?.process?.additional_unit &&
                              row?.process?.additional_unit !== ''
                                ? row?.process?.additional_unit
                                : row?.process?.produce_material_instead
                                ? row?.process?.product_as_material?.unit
                                : row?.process?.product?.unit}
                              {(!row?.process?.additional_unit &&
                                row?.process?.produce_base_project_instead &&
                                row?.process?.base_project?.unit) ||
                                ''}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </TableCell>
                    <TableCell>
                      {statusDeliveryBadge(row?.process?.delivery)}
                    </TableCell>
                    <TableCell>
                      {row?.process?.delivery?.shipping_round?.name || '-'}
                    </TableCell>
                    <TableCell>
                      {row?.process?.delivery?.delivery_method?.name || '-'}
                    </TableCell>

                    <TableCell>
                      <div className="w-48">{showWorkResponsible(row)}</div>
                    </TableCell>

                    <TableCell>
                      <div className="flex flex-col flex-wrap  gap-1 ">
                        {!row?.completed && !row?.deleted && showActionButton && (
                          <div>
                            <Button
                              variant="contained"
                              color="success"
                              size="small"
                              disabled={
                                !(
                                  (!row?.responsible?.employee ||
                                    _.isEmpty(row?.responsible?.employee)) &&
                                  (me?.userData?.role?.level >= 1 ||
                                    row?.responsible?.department?._id ===
                                      me?.userData?.department?._id)
                                )
                              }
                              startIcon={<UserPlusIcon size={16} />}
                              onClick={() => {
                                handleAcceptWork(row);
                              }}
                            >
                              รับงาน
                            </Button>{' '}
                          </div>
                        )}
                        <Link to={`/mms/works/view/${row?._id}`}>
                          <Button
                            variant="contained"
                            color={'info'}
                            size={'small'}
                          >
                            รายละเอียด
                          </Button>
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Paper>
    </div>
  );
}

DeliveryWorkTable.propTypes = {
  step: MMS.StepStage,
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleStartStep: PropTypes.func.isRequired,
  handleAcceptWork: PropTypes.func.isRequired,
  handleAuditStep: PropTypes.func,
  orderByField: PropTypes.string,
  orderBy: PropTypes.string,
  setOrderByField: PropTypes.func,
  setOrderBy: PropTypes.func,
  showActionButton: PropTypes.bool,
};
