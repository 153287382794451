import React, { useEffect, useState } from 'react';
import { Printer, RefreshCw } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeliveryReport,
  DeliveryWorkTable,
  DesktopDatepicker,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { MANUFACTURING_TRANSACTION_TYPE } from '@iarcpsu/emanufac-constant';
import {
  Autocomplete,
  Button,
  Card,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _, { result } from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { api, config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function DeliveryWorks
 * @description Display a collections or a list of DeliveryWorks from database
 */

export default function DeliveryWorks({ title, subtitle }) {
  const dispatch = useDispatch();
  const step = useSelector((state) => state.step);
  const shippingRound = useSelector((state) => state.shippingRound);
  const shippingType = useSelector((state) => state.shippingType);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [orderByField, setOrderByField] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');
  const [useRedis, setIsUseRedis] = useState(true);
  const [total, setTotal] = useState(undefined);

  const [includeLog, setIsIncludeLog] = useState(false);
  const [selectedShippingRound, setSelectedShippingRound] = useState();
  const [selectedShippingType, setSelectedShippingType] = useState();
  const [selectedShippingDate, setSelectedShippingDate] = useState(null);
  const [isOnlyDeliveryAvailable, setIsOnlyDeliveryAvailable] = useState(false);

  useEffect(() => {
    dispatch(actions.stepReset());
    return () => {};
  }, [page]);

  const stepQuery = {
    page,
    size,
    isDeliveryStep: true,
    fetchProcess: true,
    name,
    forceWithoutCache: useRedis ? '' : true,
    orderBy,
    orderByField,
    includeLog: includeLog ? true : '',
    shippingDate: selectedShippingDate
      ? dayjs(selectedShippingDate).format('YYYY-MM-DD')
      : '',
    shippingRound: selectedShippingRound?._id || '',
    shippingType: selectedShippingType?._id || '',
    onlyDeliveryAvailable: isOnlyDeliveryAvailable ? true : '',
    all: !!includeLog,
    incompleted: true,
  };

  const getAllStep = (forceCache = false) => {
    try {
      const query = {
        ...stepQuery,
        forceWithoutCache: (() => {
          if (useRedis) {
            return forceCache ? true : '';
          }
          return true;
        })(),
      };
      dispatch(actions.stepAll(query));
    } catch (error) {
      dispatch(actions.stepError());
    }
  };

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.shippingRoundAll({
          name: '',
          page: 1,
          size: config.maxFetchSize,
        }),
      );
      dispatch(
        actions.shippingTypeAll({
          name: '',
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      alert(`ไม่สามารถดึงข้อมูลสนับสนุนได้ ${error.message}`);
    }
  };

  useEffect(() => {
    getAllStep();
    return () => {};
  }, [
    page,
    size,
    name,
    orderBy,
    orderByField,
    useRedis,
    selectedShippingRound,
    selectedShippingType,
    selectedShippingDate,
    includeLog,
    isOnlyDeliveryAvailable,
  ]);

  useEffect(() => {
    queryDataFromServer();

    return () => {};
  }, []);

  useEffect(() => {
    setTotal(step?.total);
    return () => {};
  }, [step]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handlePrint = async () => {
    try {
      const searchParam = new URLSearchParams({
        ...stepQuery,
        page: 1,
        size: config.maxFetchSize,
        productName: name || '',
      });
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/step?${searchParam.toString()}`,
      );
      console.log('Data', data);
      DeliveryReport({
        information,
        pictureError: false,
        stepRows: data?.rows,
      });
    } catch (error) {
      alert(error?.message);
    }
  };

  const handleAcceptWork = async (stepInfo) => {
    Swal.fire({
      title: 'ยืนยันการรับงาน',
      text: 'คุณต้องการรับงานนี้ใช่หรือไม่?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then(async (returnResult) => {
      if (returnResult.isConfirmed) {
        try {
          await dispatch(
            actions.stepPut(stepInfo?._id, {
              responsible: {
                department: stepInfo?.responsible?.department?._id,
                employee: me?.userData?._id,
                accepted_date: dayjs().toISOString(),
              },
              employeeId: me?.userData?._id,
              manufacturing_transaction_type:
                MANUFACTURING_TRANSACTION_TYPE.WORK_ACCEPT.status_code,
            }),
          );
          getAllStep(true);
        } catch (error) {
          window.alert('รับงานไม่สำเร็จ');
          console.error('Error on Accept Work', error);
        }
      }
    });
  };

  // Component Rendering
  const renderTitle = () => (
    <div className="flex justify-between items-center">
      <ViewTitle title={title} subtitle={subtitle} />
      <div>
        <Button
          variant="contained"
          color="teal"
          startIcon={<RefreshCw size={16} />}
          onClick={() => {
            setIsUseRedis(false);
          }}
        >
          รีเฟรช
        </Button>{' '}
        <Button
          variant="contained"
          color="info"
          startIcon={<Printer size={16} />}
          onClick={handlePrint}
        >
          พิมพ์
        </Button>
      </div>
    </div>
  );

  if (step.isLoading) {
    return <Loading />;
  }
  if (!step.isLoading && step.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <Card>
            <div className="p-2">
              <div className="flex flex-wrap items-center">
                <div className="w-full md:w-1/2 lg:w-1/3 py-2 px-1">
                  <TextField
                    fullWidth
                    size="small"
                    label="ค้นหา"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-1/6 py-2 px-1">
                  {!_.isEmpty(shippingRound?.rows) && (
                    <Autocomplete
                      disablePortal
                      options={shippingRound?.rows}
                      size="small"
                      placeholder="รอบจัดส่ง"
                      onChange={(e, newValue) => {
                        setSelectedShippingRound(newValue);
                      }}
                      className="z-40"
                      // prettier-ignore
                      getOptionLabel={(option) => `${option?.name || ''}`
                }
                      renderInput={(params) => (
                        <TextField label="รอบจัดส่ง" {...params} />
                      )}
                    />
                  )}
                </div>
                <div className="w-full md:w-1/2 lg:w-1/6 py-2 px-1">
                  {!_.isEmpty(shippingType?.rows) && (
                    <Autocomplete
                      disablePortal
                      options={shippingType?.rows}
                      size="small"
                      placeholder="วิธีการจัดส่ง"
                      onChange={(e, newValue) => {
                        setSelectedShippingType(newValue);
                      }}
                      className="z-40"
                      // prettier-ignore
                      getOptionLabel={(option) => `${option?.name || ''}`
                }
                      renderInput={(params) => (
                        <TextField label="วิธีการจัดส่ง" {...params} />
                      )}
                    />
                  )}
                </div>
                <div className="w-full md:w-1/2 lg:w-1/4 py-2 px-1">
                  <DesktopDatepicker
                    fullWidth
                    size="small"
                    label="วันที่จัดส่ง"
                    value={selectedShippingDate}
                    onChange={(e) => setSelectedShippingDate(e)}
                  />
                </div>
              </div>
            </div>
          </Card>
          <Card className="my-2">
            <div className="flex flex-wrap items-center">
              <div className="w-full md:w-1/2 lg:w-1/4 py-2 px-1">
                <div className="flex gap-1 items-center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={includeLog}
                        onChange={(e) => setIsIncludeLog(e.target.checked)}
                      />
                    }
                    label="รวมคำสั่งที่เสร็จแล้ว"
                  />
                </div>
              </div>{' '}
              <div className="w-full md:w-1/2 lg:w-1/4 py-2 px-1">
                <div className="flex gap-1 items-center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isOnlyDeliveryAvailable}
                        onChange={(e) =>
                          setIsOnlyDeliveryAvailable(e.target.checked)
                        }
                      />
                    }
                    label="แสดงเฉพาะงานที่พร้อมส่ง"
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="my-2">
          <DeliveryWorkTable
            handleChangePage={(e, newPage) => {
              setPage(newPage + 1);
            }}
            handleChangeRowsPerPage={(e) => {
              setSize(e.target.value);
              setPage(1);
            }}
            orderBy={orderBy}
            orderByField={orderByField}
            setOrderBy={setOrderBy}
            setOrderByField={setOrderByField}
            page={page}
            showActionButton
            size={size}
            step={step}
            total={total}
            handleAcceptWork={handleAcceptWork}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

DeliveryWorks.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DeliveryWorks.defaultProps = {
  title: '',
  subtitle: '',
};
