import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

dayjs.extend(buddhistEra);

function DesktopDatepicker({
  label,
  value,
  setValue = () => {},
  onChange = () => {},
  inputFormat = 'DD/MM/YYYY',
  size = 'normal',
  fullWidth = false,
  views,
}) {
  const handleChange = (inputValue) => {
    setValue(inputValue);
    onChange(inputValue);
  };
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="th"
      dateFormats={{
        year: 'BBBB',
      }}
    >
      <DatePicker
        label={label}
        value={dayjs(value)}
        onChange={handleChange}
        format={inputFormat}
        views={views}
        size={size}
        fullWidth={fullWidth}
        slotProps={{
          textField: {
            fullWidth,
            size,
            error: false,
            clearable: true,
          },
          field: { clearable: true },
          actionBar: {
            actions: ['clear'],
          },
        }}
      />
    </LocalizationProvider>
  );
}

DesktopDatepicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  inputFormat: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  views: PropTypes.arrayOf(PropTypes.string),
};

export default DesktopDatepicker;
