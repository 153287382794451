/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import { COST_ESTIMATION_ELEMENT } from '@iarcpsu/emanufac-constant';
import calculateCapitalCost from './type/calculateCaptitalCost';
import calculateLaborCost from './type/calculateLaborCost';
import calculateMaterialCost from './type/calculateMaterialCost';

const calculateCostOnProfile = async ({
  previousLotList,
  profile,
  currentLot,
  api,
  isFrontend,
  information,
  mongooseModel = {
    PlaceLaborCost: {},
    PayrollAdjustment: {},
    FactoryCapitalCost: {},
    MaterialStockLot: {},
    ObjectId: () => {},
  },
  enableElement = {
    material: true,
    labor: true,
    capital_cost: true,
  },
  splitCostDriver = false,
}) => {
  try {
    const result = [];

    // บางครั้งเราต้องการเฉพาะข้อมูลวัตถุดิบอย่างเดียว แรงงานอย่างเดียว เราเลยกรองตรงนี้
    // Sometime we want only material data, labor data, or capital cost data, so we filter here
    const filterWarehouseProfile = _.filter(profile?.warehouses, (e) => {
      // prettier-ignore
      if (
        enableElement.material
        && e.cost_estimation_element
          === COST_ESTIMATION_ELEMENT.MATERIAL.status_code
      ) return true;
      // prettier-ignore
      if (
        enableElement.labor
        && e.cost_estimation_element === COST_ESTIMATION_ELEMENT.LABOR.status_code
      ) return true;
      // prettier-ignore
      if (
        enableElement.capital_cost
        && e.cost_estimation_element
          === COST_ESTIMATION_ELEMENT.CAPITAL_COST.status_code
      ) return true;
    });

    // Looping inside each warehouse in profile
    for await (const warehouseProfile of filterWarehouseProfile) {
      let cost = 0;
      let costList = [];

      switch (warehouseProfile.cost_estimation_element) {
        case COST_ESTIMATION_ELEMENT.MATERIAL.status_code:
          ({ cost, costList } = await calculateMaterialCost({
            warehouseProfile,
            previousLotList,
            currentLot,
            api,
            isFrontend,
            information,
            mongooseModel,
          }));
          break;

        case COST_ESTIMATION_ELEMENT.LABOR.status_code:
          ({ cost, costList } = await calculateLaborCost({
            warehouseProfile,
            previousLotList,
            currentLot,
            api,
            isFrontend,
            information,
            mongooseModel,
          }));
          break;

        case COST_ESTIMATION_ELEMENT.CAPITAL_COST.status_code:
          ({ cost, costList } = await calculateCapitalCost({
            warehouseProfile,
            previousLotList,
            currentLot,
            api,
            isFrontend,
            information,
            mongooseModel,
            splitCostDriver,
          }));
          break;

        default:
          break;
      }

      const tempResult = {
        name: warehouseProfile?.name,
        _id: warehouseProfile?._id,
        cost,
        cost_element: warehouseProfile.cost_estimation_element,
        costList,
      };
      result.push(tempResult);
    }
    const totalCost = _.sumBy(result, 'cost');
    return {
      profile_name: profile.name,
      profile: profile?._id,
      cost: totalCost,
      receipt_date: currentLot?.receipt_date,
      result,
    };
  } catch (error) {
    console.error('Calculate Cost Error', error.message);
    return { cost: 0, result: [], error: error.message };
  }
};

export default calculateCostOnProfile;
