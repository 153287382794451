import React from 'react';
import { Link } from 'react-router-dom';
import { CampaignModal } from '@iarcpsu/emanufac-components/src/components';
import { Button } from '@mui/material';

import LogoList from '../assets/images/logo-list.png';

export default function SystemSlowApologize() {
  return (
    <CampaignModal
      campaignCode="NOTIFY_250401"
      title="ประกาศเรื่องระบบแจ้งเตือนผ่าน Line Notify"
    >
      <div>
        {/* <div className="flex justify-center">
          <img src={Santa} className="w-32" />
        </div> */}
        {/* <div className="text-xl font-semibold text-center my-2">
          ขออภัยในการทำงานที่ล่าช้าของระบบ
        </div> */}
        <div className="text-center ">
          เนื่องจากทาง Line ประเทศไทย ได้ยุติการให้บริการ Line Notify
          ตั้งแต่วันที่ 31 มีนาคม 2568 ที่ผ่านมา
          ทำให้ท่านไม่สามารถรับการแจ้งเตือนผ่าน Line
          ได้อีกตั้งแต่วันนี้เป็นต้นไป
          <br />
          แต่เราได้เตรียมการแจ้งเตือนแบบใหม่ ในรูปแบบของ Web Push Notification
          และ In App Notification ไว้ให้ผ่านแล้ว
          โดยท่านสามารถเข้าไปตั้งค่าได้ที่
          <br />
          <Link to="/profile/main">
            <Button variant="contained" className="my-2">
              หน้าโปรไฟล์{' '}
            </Button>
          </Link>
          <br />
          สำหรับวิธีการตั้งค่าในแต่ละอุปกรณ์ท่านสามารถศึกษาได้ที่
          <br />
          <a
            href="https://iarc-programing.github.io/docs/emanufac/notify/inapp-notify"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="contained" color="inherit" className="my-2">
              วิธีการตั้งค่าอุปกรณ์ให้รองรับ
            </Button>
          </a>
        </div>

        <div className="text-center ">
          <br />
          เราขออภัยในความไม่สะดวกที่เกิดขึ้น ทางเรายังคงมีการพัฒนา
          เพื่อจะส่งมอบประสบการณ์ที่ดีให้กับทุกๆ ท่าน
        </div>
        <div className="flex justify-center my-4">
          <img src={LogoList} className="h-12" />
        </div>
      </div>
    </CampaignModal>
  );
}
