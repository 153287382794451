import DeliveryWorks from './DeliveryWorks';
import DetailWork from './DetailWork';
import EditStepMeasurement from './EditStepMeasurement';
import HistoryWork from './HistoryWork';
import MyDeliveryWorks from './MyDeliveryWorks';
import MyWorks from './MyWorks';
import PluginEditData from './PluginEditData';
import WorkByStatus from './WorkByStatus';
import Works from './Works';

export {
  DeliveryWorks,
  DetailWork,
  EditStepMeasurement,
  HistoryWork,
  MyDeliveryWorks,
  MyWorks,
  PluginEditData,
  WorkByStatus,
  Works,
};

export default {
  DetailWork,
  EditStepMeasurement,
  HistoryWork,
  MyWorks,
  PluginEditData,
  WorkByStatus,
  Works,
  DeliveryWorks,
  MyDeliveryWorks,
};
