import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { NotifySettingForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import PropTypes from 'prop-types';

import * as actions from '../../redux/actions';

function NotifySetting({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const department = useSelector((state) => state.department);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      setting: {
        ...information?.setting,
        notify: {
          ...information?.setting?.notify,
          notify_channel: {
            ...information?.setting?.notify?.notify_channel,
          },
        },
      },
    },
  });

  useEffect(() => {
    const fetchAllDepartments = async () => {
      try {
        await dispatch(actions.departmentAll({}));
      } catch (error) {
        console.error('Fetch Departments Error', error);
      }
    };

    fetchAllDepartments();
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      console.log('data', data);
      await dispatch(actions.informationPut(information.id, data));
      alert('สำเร็จ');
      await dispatch(actions.informationAll());
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <Card>
          <CardContent>
            <div className="font-semibold">แผนกที่รับแจ้งเตือน</div>
            <NotifySettingForm
              control={control}
              information={information}
              errors={errors}
              department={department}
            />{' '}
          </CardContent>
        </Card>
      </div>

      <div className="my-2">
        <Card>
          <CardContent>
            <div className="font-semibold">ช่องทางการแจ้งเตือน</div>
            <div className="flex flex-col gap-2">
              <Controller
                control={control}
                name="setting.notify.notify_channel.lineNotify"
                render={({ field }) => (
                  <FormControlLabel
                    label="Line Notify (สิ้นสุดการให้บริการในวันที่ 31 มีนาคม 2568)"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name="setting.notify.notify_channel.lineMessagingAPI"
                render={({ field }) => (
                  <FormControlLabel
                    label="Line Official Account & Message API"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name="setting.notify.notify_channel.firebaseMessaging"
                render={({ field }) => (
                  <FormControlLabel
                    label="In App Notification (FCM)"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="flex flex-row justify-end gap-1 py-4">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </form>
  );

  if (information.isLoading) {
    return <Loading />;
  }
  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
NotifySetting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

NotifySetting.defaultProps = {
  title: '',
  subtitle: '',
};

export default NotifySetting;
