import FactoryCapitalCostTable from './CEM/FactoryCapialCostTable';
import PlaceLaborCostByDayTableRow from './CEM/PlaceLaborCostByDayTableRow';
import PlaceLaborCostTable from './CEM/PlaceLaborCostTable';
import GeneralTableRendering from './common/GeneralTableRendering';
import PayrollTable from './HRMS/PayrollTable';
import ManyProductStockLotTable from './IMS-WMS/ManyProductStockLotTable';
import MaterialAmountPivotTable from './IMS-WMS/MaterialAmountPivotTable';
import MaterialLotCostEstimationTable from './IMS-WMS/MaterialLotCostEstimationTable';
import MaterialCostTable from './IMS-WMS/MaterialLotCostTable';
import MaterialLotDurationTable from './IMS-WMS/MaterialLotDurationTable';
import MaterialLotTable from './IMS-WMS/MaterialLotTable';
import MaterialPivotTable from './IMS-WMS/MaterialPivotTable';
import MaterialStockLotTable from './IMS-WMS/MaterialStockLotTable';
import MaterialStockLotWithoutMaterialNameTable from './IMS-WMS/MaterialStockLotWithOutMaterialNameTable';
import MaterialToProductPivotTable from './IMS-WMS/MaterialToProductPivotTable';
import MaterialTransactionTable from './IMS-WMS/MaterialTransactionTable';
import ProductAmountTable from './IMS-WMS/ProductAmountTable';
import ProductStockLotTable from './IMS-WMS/ProductStockLotTable';
import ProductStockLotWithOutProductNameTable from './IMS-WMS/ProductStockLotWithOutProductNameTable';
import MaterialOnProcessTable from './MMS/backup/MaterialOnProcessTable';
import DeliveryWorkTable from './MMS/DeliveryWorkTable';
import FullManufacMaterialTable from './MMS/FullManufacMaterialTable';
import ManufacturingMaterialTable from './MMS/ManufacturingMaterialTable';
import ManufacturingOrderTable from './MMS/ManufacturingOrderTable';
import MaterialSplittingTable from './MMS/MaterialSplittingTable';
import MaterialWithdrawInfoTable from './MMS/MaterialWithdrawInfoTable';
import MeasurementDataTable from './MMS/MeasurementDataTable';
import MeasurementTypeTable from './MMS/MeasurementTypeTable';
import MOMaterialBomTable from './MMS/MOMaterialBomTable';
import ProcessTable from './MMS/ProcessTable';
import ProcessTemplateTable from './MMS/ProcessTemplateTable';
import ProcessWithMeasurementTable from './MMS/ProcessWithMeasurementTable';
import ReportMaterialProductAsMaterial from './MMS/ReportMaterialProductAsMaterial';
import ReportMaterialProductResultTable from './MMS/ReportMaterialProductResultTable';
import ReportMaterialProgressTable from './MMS/ReportMaterialProgressTable';
import StepTable from './MMS/StepTable';
import TotalMaterialBomTable from './MMS/TotalMaterialBomTable';
import InternalDepositTable from './SPM/InternalDepositTable';
import OrderTable from './SPM/OrderTable';
import QuotationProductTable from './SPM/QuotationProductTable';
import QuotationTable from './SPM/QuotationTable';
import QuotationTransactionTable from './SPM/QuotationTransactionTable';

export {
  DeliveryWorkTable,
  FactoryCapitalCostTable,
  FullManufacMaterialTable,
  GeneralTableRendering,
  InternalDepositTable,
  ManufacturingMaterialTable,
  ManufacturingOrderTable,
  ManyProductStockLotTable,
  MaterialAmountPivotTable,
  MaterialCostTable,
  MaterialLotCostEstimationTable,
  MaterialLotDurationTable,
  MaterialLotTable,
  MaterialOnProcessTable,
  MaterialPivotTable,
  MaterialSplittingTable,
  MaterialStockLotTable,
  MaterialStockLotWithoutMaterialNameTable,
  MaterialToProductPivotTable,
  MaterialTransactionTable,
  MaterialWithdrawInfoTable,
  MeasurementDataTable,
  MeasurementTypeTable,
  MOMaterialBomTable,
  OrderTable,
  PayrollTable,
  PlaceLaborCostByDayTableRow,
  PlaceLaborCostTable,
  ProcessTable,
  ProcessTemplateTable,
  ProcessWithMeasurementTable,
  ProductAmountTable,
  ProductStockLotTable,
  ProductStockLotWithOutProductNameTable,
  QuotationProductTable,
  QuotationTable,
  QuotationTransactionTable,
  ReportMaterialProductAsMaterial,
  ReportMaterialProductResultTable,
  ReportMaterialProgressTable,
  StepTable,
  TotalMaterialBomTable,
};

export default {
  FullManufacMaterialTable,
  ManufacturingMaterialTable,
  ManufacturingOrderTable,
  MaterialOnProcessTable,
  MaterialStockLotTable,
  MaterialWithdrawInfoTable,
  MeasurementDataTable,
  MeasurementTypeTable,
  MOMaterialBomTable,
  OrderTable,
  PayrollTable,
  ProcessTable,
  ProcessTemplateTable,
  ProcessWithMeasurementTable,
  ReportMaterialProductAsMaterial,
  ReportMaterialProductResultTable,
  ReportMaterialProgressTable,
  StepTable,
  TotalMaterialBomTable,
  GeneralTableRendering,
  MaterialTransactionTable,
  ProductStockLotWithOutProductNameTable,
  MaterialStockLotWithoutMaterialNameTable,
  MaterialSplittingTable,
  MaterialPivotTable,
  MaterialToProductPivotTable,
  MaterialAmountPivotTable,
  ProductAmountTable,
  ProductStockLotTable,
  ManyProductStockLotTable,
  MaterialCostTable,
  MaterialLotTable,
  MaterialLotDurationTable,
  QuotationTable,
  InternalDepositTable,
  QuotationProductTable,
  QuotationTransactionTable,
  PlaceLaborCostTable,
  FactoryCapitalCostTable,
  MaterialLotCostEstimationTable,
  PlaceLaborCostByDayTableRow,
  DeliveryWorkTable,
};
