import React from 'react';
import { MANUFACTURING_TRANSACTION_TYPE } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';

import NameBox from '../Common/NameBox';

dayjs.extend(relativeTime);

const StepTimeBox = ({ selectedProcess, manufacturingTransaction }) => {
  console.log('selectedProcess step', selectedProcess?.steps);
  console.log('manufacturingTransaction rows', manufacturingTransaction?.rows);
  return (
    <div>
      {_.map(selectedProcess?.steps, (step, index) => {
        const filterTransaction = _.filter(
          manufacturingTransaction?.rows,
          (each) => each?.step?._id === step?._id,
        );
        const foundAccept = _.findLast(
          filterTransaction,
          (each) =>
            each?.manufacturing_transaction_type ===
              MANUFACTURING_TRANSACTION_TYPE.WORK_ACCEPT.status_code ||
            each?.manufacturing_transaction_type ===
              MANUFACTURING_TRANSACTION_TYPE.WORK_START.status_code,
        );
        const foundClose = _.findLast(
          filterTransaction,
          (each) =>
            each?.manufacturing_transaction_type ===
            MANUFACTURING_TRANSACTION_TYPE.WORK_CLOSE.status_code,
        );
        console.log('Filter transaction', filterTransaction);
        return (
          <div key={index} className="my-2 border p-4 rounded-md">
            <div className="font-semibold">{step?.name}</div>
            <div className="my-1">
              <NameBox user={step?.responsible?.employee} />
            </div>
            <div className="flex justify-between">
              <div className="text-sm">
                รับงาน :{' '}
                {foundAccept?.createdAt
                  ? dayjs(foundAccept?.createdAt).format('D MMM HH:mm')
                  : ''}{' '}
              </div>{' '}
              <div className="text-sm">
                ปิดงาน :{' '}
                {foundClose?.createdAt
                  ? dayjs(foundClose?.createdAt).format('D MMM HH:mm')
                  : ''}{' '}
              </div>
            </div>
            {foundAccept?.createdAt && foundClose?.createdAt ? (
              <div>
                เวลาอยู่ในระยะ{' '}
                {dayjs(foundClose?.createdAt).diff(
                  foundAccept?.createdAt,
                  'day',
                ) + 1}{' '}
                วัน
              </div>
            ) : (
              <div>ยังคำนวนเวลาไม่ได้</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default StepTimeBox;
