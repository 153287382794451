import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  BackButton,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';

import 'dayjs/locale/th';

import * as actions from '../../../redux/actions';

dayjs.extend(BuddhistCalendar);

/**
 * @function SettingMMS
 * @memberof MMS/Setting
 * @description ตั้งค่าทั่วไปเกี่ยวกับโมดูลการผลิต มีรายการตั้งค่าเกี่ยวกับแดชบอร์ด และเมนูเป็นหลัก
 */

const SettingMMS = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const measurementType = useSelector((state) => state.measurementType);
  const pdfReport = useSelector((state) => state.pdfReport);
  const mmsDashboard = useSelector((state) => state.mmsDashboard);
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      setting: {
        ...information?.setting,
        mms: {
          ...information?.setting?.mms,
          customDashboard: _.map(
            information?.setting?.mms?.customDashboard,
            (eachDashboard) => eachDashboard?._id,
          ),
        },
      },
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'setting.mms.customDashboard',
  });

  const {
    fields: reportField,
    append: reportAppend,
    remove: reportRemove,
  } = useFieldArray({
    control,
    name: 'setting.mms.customReport',
  });

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        await dispatch(actions.departmentAll({}));
      } catch (error) {
        console.error('Fetch Department Error', error);
      }
    };
    const fetchMeasurementType = async () => {
      try {
        await dispatch(actions.measurementTypeAll({}));
      } catch (error) {
        console.error('Fetch Department Error', error);
      }
    };
    const fetchMMSDashboard = async () => {
      try {
        await dispatch(
          actions.mmsDashboardAll({ page: 1, size: config.maxFetchSize }),
        );
      } catch (error) {
        console.error('Fetch MMS Dashboard Error', error);
      }
    };
    const fetchPDFReport = async () => {
      try {
        await dispatch(
          actions.pdfReportAll({ page: 1, size: config.maxFetchSize }),
        );
      } catch (error) {
        console.error('Fetch MMS Dashboard Error', error);
      }
    };

    fetchMeasurementType();
    fetchDepartment();
    fetchMMSDashboard();
    fetchPDFReport();
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeSetting = async (data) => {
    console.log('Data', data);
    const confirm = window.confirm('ยืนยันการปรับการตั้งค่า');
    if (confirm) {
      try {
        await dispatch(
          actions.informationPut(information?._id, {
            ...data,
            setting: {
              ...data.setting,
              mms: {
                ...data.setting.mms,
                fixedLastStep: {
                  ...data.setting.mms.fixedLastStep,
                  measurementTypes: _.map(
                    data?.setting?.mms?.fixedLastStep?.measurementTypes,
                    (_eachMMType) => _eachMMType?._id,
                  ),
                },
              },
            },
          }),
        );
        await dispatch(actions.informationAll());
        window.alert('แก้ไขการตั้งค่าสำเร็จ');
      } catch (error) {
        window.alert('แก้ไขการตั้งค่าไม่สำเร็จ');
        console.error('แก้ไขการตั้งค่าไม่สำเร็จ', error);
      }
    }
  };

  const renderGeneralSetting = () => (
    <Card className="p-4">
      <div className="flex flex-wrap w-full ">
        {' '}
        <div className="w-full py-2 px-2 font-semibold font-display ">
          แดชบอร์ดรายละเอียดคำสั่งผลิต
        </div>
        <div className="w-1/2 py-2 px-2">แดชบอร์ด</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            <Controller
              control={control}
              name="setting.mms.dashboard.showDashboardA"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงแดชบอร์ด A"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.dashboard.showDashboardB"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงแดชบอร์ด B"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.dashboard.showDashboardC"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงแดชบอร์ด C"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.mms.dashboard.showBomDashboard"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงแดชบอร์ดสำหรับใส่ค่าส่วนผสม Bom"
                />
              )}
            />
          </div>
        </div>
        <div className="w-1/2 py-2 px-2">แดชบอร์ดกำหนดเอง</div>
        <div className="w-1/2 py-2 px-2">
          {_.map(fields, (eachField, index) => (
            <div key={eachField?.id} className="my-2">
              <div className="flex gap-2">
                <div className="w-3/4">
                  <Controller
                    control={control}
                    name={`setting.mms.customDashboard[${index}]`}
                    render={({ field }) => (
                      <FormControl fullWidth size="small">
                        <InputLabel>แดชบอร์ด</InputLabel>
                        <Select
                          size="small"
                          fullWidth
                          {...field}
                          label="แดชบอร์ด"
                        >
                          {_.map(
                            mmsDashboard?.rows,
                            (eachMMSDashboard, mmDIndex) => (
                              <MenuItem
                                key={`mms-dashboard-${mmDIndex}`}
                                value={eachMMSDashboard?._id}
                              >
                                {eachMMSDashboard?.name}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="w-1/4">
                  <Button
                    size="small"
                    color="error"
                    variant="contained"
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </div>
              </div>
            </div>
          ))}
          <div>
            <Button variant="contained" size="small" onClick={() => append({})}>
              เพิ่ม
            </Button>
          </div>
        </div>
        <div className="w-1/2 py-2 px-2">รายงานกำหนดเอง</div>
        <div className="w-1/2 py-2 px-2">
          {_.map(reportField, (eachField, index) => (
            <div key={eachField?.id} className="my-2">
              <div className="flex gap-2">
                <div className="w-3/4">
                  <Controller
                    control={control}
                    name={`setting.mms.customReport[${index}]`}
                    defaultValue={eachField?._id}
                    render={({ field }) => (
                      <FormControl fullWidth size="small">
                        <InputLabel>รายงาน</InputLabel>
                        <Select
                          size="small"
                          fullWidth
                          {...field}
                          label="รายงาน"
                        >
                          {_.map(pdfReport?.rows, (eachReport, mmDIndex) => (
                            <MenuItem
                              key={`report-${mmDIndex}`}
                              value={eachReport?._id}
                            >
                              {eachReport?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="w-1/4">
                  <Button
                    size="small"
                    color="error"
                    variant="contained"
                    onClick={() => reportRemove(index)}
                  >
                    ลบ
                  </Button>
                </div>
              </div>
            </div>
          ))}
          <div>
            <Button
              variant="contained"
              size="small"
              onClick={() => reportAppend({})}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">เมนู</div>
        <div className="w-1/2 py-2 px-2">เมนูที่ต้องการให้แสดงในคำสั่งผลิต</div>
        <div className="w-1/2 py-2 px-2 flex flex-col">
          <Controller
            control={control}
            name="setting.mms.menu.dashboard"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แดชบอร์ด"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.mms.menu.manufacturingOrder"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="คำสั่งผลิต"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.mms.menu.productionLine"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="ไลน์การผลิต"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.mms.menu.works"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="งาน"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.mms.menu.myWorks"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="งานของฉัน"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.mms.menu.manufacturingOrderHistory"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="ประวัติคำสั่งผลิต"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.mms.menu.manufacturingOrderReport"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="รายงานคำสั่งผลิต"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.mms.menu.materialUsageReport"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="รายงานการใช้วัตถุดิบ"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.mms.menu.bomReport"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="รายงานส่วนผสม"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.mms.menu.variableReport"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="ค่าตัวแปรที่เก็บได้"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.mms.menu.delivery"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="การจัดส่ง"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.mms.menu.myDelivery"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="การจัดส่งของฉัน"
              />
            )}
          />{' '}
        </div>
      </div>
    </Card>
  );

  if (measurementType.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <form onSubmit={handleSubmit(handleChangeSetting)}>
        <div>{renderGeneralSetting()}</div>
        <div className="flex justify-end mt-2">
          <Button color="primary" variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
};

SettingMMS.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingMMS.defaultProps = {
  title: '',
  subtitle: '',
};
export default SettingMMS;
