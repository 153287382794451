import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

import serviceAccount from './serviceAccount.json';
import pushCertificate from './webPushCertificate.json';

export const firebaseConfig = serviceAccount;

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const vapidKey = pushCertificate.publicKey;
export { messaging };
export default app;
