/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';

import currencyFormatterTH from '@/utils/functions/currencyFormatterTH';

import 'addthaifont-pdfmake/build/vfs_fonts';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

function splitter(inputString, l) {
  let str = inputString;
  const strs = [];
  while (str.length > l) {
    let pos = str.substring(0, l).lastIndexOf(' ');
    pos = pos <= 0 ? l : pos;
    strs.push(str.substring(0, pos));
    let i = str.indexOf(' ', pos) + 1;
    if (i < pos || i > pos + l) {
      i = pos;
    }
    str = str.substring(i);
  }
  strs.push(str);
  return strs;
}

const generateProductRemark = (remark) => {
  const splitWithLodash = _.split(remark, ' ');
  const newWordResult = _.map(splitWithLodash, (word) => {
    if (_.size(word) > 40) {
      const splitedRemark = splitter(word, 40);
      return _.join(splitedRemark, '\n');
    }

    return word;
  });

  return _.join(newWordResult, ' ');
};

const genOrder = (data, information) =>
  _.map(_.filter(data), (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${
        _data?.process?.delivery?.delivery_date
          ? dayjs(_data?.process?.delivery?.delivery_date).format('D MMM BBBB ')
          : '-'
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: ` ${_data?.customer?.type_code || ''} ${
        _data?.customer?.name || '-'
      }`,
      border: [true, false, true, true],
      fontSize: 10,

      colSpan: 1,
    },
    {
      text: ` ${_data?.product?.type_code || ''} ${
        _data?.product?.name || '-'
      }`,
      border: [true, false, true, true],
      fontSize: 10,

      colSpan: 1,
    },
    {
      text: _data?.process?.delivery?.shipping_round?.name || '',
      border: [true, false, true, true],
      fontSize: 10,

      colSpan: 1,
    },
    {
      text: _data?.process?.delivery?.delivery_method?.name || '',
      border: [true, false, true, true],
      fontSize: 10,

      colSpan: 1,
    },
    {
      text: `${
        _data?.responsible?.employee && !_.isEmpty(_data?.responsible?.employee)
          ? `${_data?.responsible?.employee?.firstname || ''} `
          : ''
      }`,
      border: [true, false, true, true],
      fontSize: 10,

      colSpan: 1,
    },
    {
      text: _data?.process?.delivery?.delivery_remark || '',
      border: [true, false, true, true],
      fontSize: 10,

      colSpan: 1,
    },
  ]);

const DeliveryReport = ({ information, pictureError, stepRows }) => {
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'รายการจัดส่งสินค้า',
    },
    content: [
      pictureError
        ? ReportHeader(information, false)
        : ReportHeader(information, true),
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 0, 0, 10],
        text: 'รายการจัดส่งสินค้า',
        fontSize: '16',
        bold: true,
      },
      // {
      //   style: 'tableExample',
      //   table: {
      //     widths: ['10%', '40%', '18%', '*'],
      //     heights: [15],
      //     body: [
      //       [
      //         {
      //           text: 'รหัสลูกค้า',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, true, false, false],
      //         },
      //         {
      //           text: `${manufacturingOrder?.customer?.type_code || '-'}`,
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, true, true, false],
      //         },
      //         {
      //           text: 'เลขที่เอกสาร',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, true, false, false],
      //         },
      //         {
      //           text: `${manufacturingOrder?.running_number || ''}`,
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, true, true, false],
      //         },
      //       ],
      //       [
      //         {
      //           text: 'ลูกค้า',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, false, false, false],
      //         },
      //         {
      //           text: `${manufacturingOrder?.customer?.name || '-'}`,
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, false, true, false],
      //         },
      //         {
      //           text: 'วันที่สั่งผลิต',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, false, false, false],
      //         },
      //         {
      //           text: `${dayjs(manufacturingOrder?.start_date)
      //             .locale('th')
      //             .format(
      //               information?.setting?.mms?.timing?.showTime
      //                 ? 'DD MMM BBBB เวลา HH.mm น.'
      //                 : 'DD MMM BBBB',
      //             )}`,
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, false, true, false],
      //         },
      //       ],
      //       [
      //         {
      //           text: 'ที่อยู่',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, false, false, false],
      //         },
      //         {
      //           text: `${manufacturingOrder?.customer?.address || '-'}`,
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, false, true, false],
      //         },
      //         {
      //           text: information?.setting?.mms?.manufacturingOrder
      //             ?.showPaymentMethod
      //             ? 'ช่องทางการชำระเงิน'
      //             : '',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, false, false, false],
      //         },
      //         {
      //           text: information?.setting?.mms?.manufacturingOrder
      //             ?.showPaymentMethod
      //             ? `${manufacturingOrder?.payment_method?.name || '-'}`
      //             : '',
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, false, true, false],
      //         },
      //       ],
      //       [
      //         {
      //           text: '',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, false, false, false],
      //         },
      //         {
      //           text: `โทร ${
      //             manufacturingOrder?.customer?.phone_number || '-'
      //           }`,
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, false, false, false],
      //         },
      //         {
      //           text: 'ผู้สั่งผลิต',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, false, false, false],
      //         },
      //         {
      //           text: ` ${manufacturingOrder?.assigner?.firstname || '-'} ${
      //             manufacturingOrder?.assigner?.lastname || ''
      //           }`,
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, false, true, false],
      //         },
      //       ],
      //       [
      //         {
      //           text: '',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, false, false, false],
      //         },
      //         {
      //           text: '',
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, false, false, false],
      //         },
      //         {
      //           text: information?.setting?.mms?.manufacturingOrder
      //             ?.showQuotation
      //             ? 'เลขที่ใบเสนอราคา'
      //             : '',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, false, false, false],
      //         },
      //         {
      //           text: `${
      //             information?.setting?.mms?.manufacturingOrder?.showQuotation
      //               ? manufacturingOrder?.quotation_number || '-'
      //               : ''
      //           }`,
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, false, true, false],
      //         },
      //       ],
      //       [
      //         {
      //           text: '',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, false, false, true],
      //         },
      //         {
      //           text: '',
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, false, false, true],
      //         },
      //         {
      //           text: information?.setting?.mms?.usingAsDefault
      //             ?.showExpectedDate
      //             ? 'วันที่กำหนดจัดส่ง'
      //             : '',
      //           colSpan: 1,
      //           bold: true,
      //           fontSize: 10,
      //           border: [true, false, false, true],
      //         },
      //         {
      //           text: `${
      //             information?.setting?.mms?.usingAsDefault?.showExpectedDate
      //               ? `${dayjs(manufacturingOrder?.expected_date)
      //                   .locale('th')
      //                   .format('DD MMM BBBB')} ${
      //                   manufacturingOrder?.express ? ' (งานด่วน)' : ''
      //                 }`
      //               : ''
      //           }`,
      //           colSpan: 1,
      //           fontSize: 10,
      //           border: [false, false, true, true],
      //         },
      //       ],
      //     ],
      //   },
      // },
      {
        margin: [0, 20, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['5%', '10%', '15%', '*', '10%', '12%', '12%', '10%'],

          // heights: (row) => {
          //   console.log('Row', row);
          //   if (row === 0) return 15;

          //   if (row > _.size(manufacturingOrder?.process)) {
          //     if (information?.setting?.mms?.manufacturingOrder?.showPrice) {
          //       return 15;
          //     }
          //     return 1;
          //   }

          //   return 40;
          // },
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'วันที่จัดส่ง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: 'ลูกค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'สินค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รอบการจัดส่ง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'วิธีการจัดส่ง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ผู้รับผิดชอบ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'หมายเหตุ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genOrder(stepRows, information),
          ],
        },
      },
    ],
    // images: {
    //   logo: information?.logo?.url,
    // },
  };
  console.log('doc definition', docDefinition);
  try {
    const newDocDefinition = docDefinition;
    if (!pictureError) {
      newDocDefinition.images = {
        logo: information?.logo?.url,
      };
    }
    pdfMake.createPdf(newDocDefinition).open();
  } catch (error) {
    pdfMake.createPdf(docDefinition).open();
  }
};

export default DeliveryReport;
