import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { BackButton } from '@iarcpsu/emanufac-components/src/components/Button';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { ViewTitle } from '@iarcpsu/emanufac-components/src/components/ViewTitle';
import { Card } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '../../redux/actions';

export default function DetailProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useSelector((state) => state.product);

  useEffect(() => {
    const getProduct = async () => {
      try {
        await dispatch(actions.productGet(id));
      } catch (error) {
        alert(`ไม่สามารถดึงข้อมูลสินค้าได้ ${error?.message}`);
      }
    };
    getProduct();
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (product.isLoading || product.rows) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold font-display ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'รหัสสินค้า'}
              </div>
              <div className="w-1/2 py-4 ">
                {product?.type_code || product?.tag || '-'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'ชื่อ'}
              </div>
              <div className="w-1/2 py-4 ">{product?.name}</div>

              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'หน่วย'}
              </div>
              <div className="w-1/2 py-4 ">
                {product?.use_unit_conversion
                  ? `${product?.unit_input?.name} - ${product?.unit_output?.name} `
                  : product?.unit}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'ประเภทสินค้า'}
              </div>
              <div className="w-1/2 py-4 ">
                {' '}
                {product?.product_type?.name || product?.type?.name || '-'}
              </div>
              {product?.net ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                    {'ปริมาตรสุทธิ'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${product?.net} ${product?.unit_net}`}</div>
                </>
              ) : (
                <></>
              )}
              {product?.packing_size ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                    {'ขนาดบรรจุ'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${product?.packing_size} ${product?.packing_unit}`}</div>
                </>
              ) : (
                <></>
              )}
              {product?.prefer_customer ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                    ลูกค้า
                  </div>
                  <div className="w-1/2 py-4 ">
                    {product?.prefer_customer?.type_code || ''}{' '}
                    {product?.prefer_customer?.name}
                  </div>
                </>
              ) : (
                <></>
              )}

              {product?.detail ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                    {'รายละเอียด'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${product?.detail}`}</div>
                </>
              ) : (
                <></>
              )}

              {product?.additional &&
                _.map(product?.additional, (item, index) => (
                  <div className="w-full flex" key={index}>
                    <div className="w-1/2 py-4 px-2 font-semibold font-display">
                      {index}
                    </div>
                    <div className="w-1/2 py-4 ">
                      {typeof item === 'object' ? item?.value || '' : item}
                    </div>
                  </div>
                ))}
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProduct.defaultProps = {
  title: '',
  subtitle: '',
};
